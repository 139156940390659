import { useContext } from 'react';
import { FilterBookingsContext } from './Booking/Filter/provider';
import { TabsContext } from './Tabs/provider';
import { OffsetPageContext } from './Pagination/provider';
import { SnackbarContext } from './Snackbar/provider';

// eslint-disable import/prefer-default-export
export const useFilterBooking = () => useContext(FilterBookingsContext);
export const useTabs = () => useContext(TabsContext);
export const useOffsetPage = () => useContext(OffsetPageContext);
export const useSnackbar = () => useContext(SnackbarContext);
