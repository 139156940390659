import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/styles';
import { InputField, FormHelperText } from '@lc/legacy/src/Components';

import api from 'api';
import { logger } from 'helpers';

import Spinner from 'components/Spinner';

const getStates = async setStates => {
  const [data, error] = await api.getAllStatesBr();
  if (!error) setStates(data.sort((a, b) => a.nome.localeCompare(b.nome)));
};

const requestCities = setCities => async id => {
  const [data, error] = await api.getCityByState(id);
  if (!error) {
    setCities(data.sort((a, b) => a.nome.localeCompare(b.nome)));
    return data;
  }
  return [];
};

const AddressForm = ({
  classes,
  initialValues,
  onSubmit,
  Footer,
  style,
  color,
  onErrorCallback,
}) => {
  const { t } = useTranslation(['forms', 'feedbacks', 'common']);
  const { register, handleSubmit, errors, clearError } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const [formValues, setFormValues] = useState(initialValues);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState({ nome: '' });
  const [selectedCity, setSelectedCity] = useState({ nome: '' });
  const [cities, setCities] = useState([]);
  const [cepExtra] = useState({
    label: t('forms:zipCodeField'),
    placeholder: t('forms:zipcodePlaceholder'),
  });
  const [isCepFocus, setIsCepFocus] = useState(false);
  const [isZipcodeFetching, setZipcodeFetching] = useState(false);
  const numberRef = useRef();
  const getCities = useCallback(id => requestCities(setCities)(id), []);

  useEffect(() => {
    setFormValues(initialValues);
    (async () => {
      try {
        if (initialValues.state) {
          setSelectedState({ nome: initialValues.state });
          const state = states.find(
            item => item.nome === initialValues.state || item.sigla === initialValues.state,
          );
          if (state) getCities(state.id);
        }
        if (initialValues.city) setSelectedCity({ nome: initialValues.city });
      } catch (error) {
        logger.logError('AddressForm getCities', { error, states, initialValues });
      }
    })();
  }, [initialValues, states, getCities]);

  useEffect(() => {
    getStates(setStates);
  }, []);

  useEffect(() => {
    onErrorCallback(errors);
  }, [Object.values(errors)]);

  const handlerCep = async cep => {
    setTimeout(() => {
      document.activeElement.blur();
    }, 100);
    try {
      setZipcodeFetching(true);
      const result = await api.getAddressByZipCode(cep);

      const state = states.filter(item => item.sigla === result.state)[0];
      const citiesList = await getCities(state.id);
      const city = citiesList.filter(item => item.nome === result.city)[0];
      setSelectedState(state);
      setSelectedCity(city);

      setFormValues({
        ...formValues,
        zipcode: cep,
        state: result.state,
        city: result.city,
        country: 'Brasil',
        street: result.street,
        neighborhood: result.neighborhood,
      });
      setZipcodeFetching(false);
      setTimeout(() => {
        numberRef.current.focus();
      }, 100);
      clearError();
    } catch (error) {
      logger.logError('handlerCep()', { error });
      setZipcodeFetching(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1} style={{ ...style, position: 'relative' }}>
        {isZipcodeFetching && (
          <div
            style={{
              position: 'absolute',
              background: '#ffffff90',
              width: '100%',
              height: '100%',
              zIndex: 1,
            }}
          >
            <Spinner color="secondary" absolute />
          </div>
        )}
        <Grid item xs={12} className={classes.zipcodeContainer}>
          <InputField
            withMask
            color={color}
            data-testid="form-zipcode"
            label={isCepFocus ? cepExtra.label : cepExtra.placeholder}
            placeholder={cepExtra.placeholder}
            name="zipcode"
            error={!!errors.zipcode}
            helperText={t('feedbacks:invalidZipcode')}
            value={formValues.zipcode}
            fullWidth
            className={classes.formField}
            inputProps={{
              ref: register({ required: true, pattern: /^\d{5}-\d{3}$/ }),
              options: { blocks: [5, 3], delimiters: ['-'] },
            }}
            onChange={e => {
              setFormValues({ ...formValues, zipcode: e.target.value });
            }}
            onFocus={() => {
              setIsCepFocus(true);
            }}
            onBlur={e => {
              setIsCepFocus(false);
              if (e.target.value) {
                handlerCep(e.target.value);
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            color={color}
            data-testid="form-street"
            label={t('addressField')}
            name="street"
            error={!!errors.street}
            value={formValues.street}
            fullWidth
            className={classes.formField}
            onChange={e => {
              setFormValues({ ...formValues, street: e.target.value });
            }}
            inputProps={{
              ref: register({ required: true }),
            }}
          />
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'left' }}>
          <InputField
            color={color}
            data-testid="form-number"
            name="number"
            label={t('addressNumberField')}
            error={!!errors.number}
            value={formValues.number}
            className={classes.formField}
            onChange={e => {
              setFormValues({ ...formValues, number: e.target.value });
            }}
            inputProps={{
              name: 'number',
              ref: ref => {
                numberRef.current = ref;
                register({ required: true })(ref);
              },
            }}
          />
        </Grid>
        <Grid item xs={8} style={{ textAlign: 'right' }}>
          <InputField
            color={color}
            data-testid="form-complement"
            name="complement"
            label={t('addressComplementField')}
            placeholder="Sala - Conjunto"
            onChange={e => {
              setFormValues({ ...formValues, complement: e.target.value });
            }}
            value={formValues.complement}
            className={classes.formField}
            inputProps={{
              ref: register({ required: false }),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            color={color}
            data-testid="form-neighborhood"
            label={t('addressNeighborhoodField')}
            name="neighborhood"
            onChange={e => {
              setFormValues({ ...formValues, neighborhood: e.target.value });
            }}
            error={!!errors.neighborhood}
            value={formValues.neighborhood}
            fullWidth
            className={classes.formField}
            inputProps={{
              name: 'neighborhood',
              ref: register({ required: true }),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            color={color}
            data-testid="form-country"
            label={t('countryField')}
            name="country"
            error={!!errors.country}
            helperText={t('feedbacks:requiredField')}
            value={formValues.country}
            fullWidth
            className={classes.formField}
            readOnly
            onChange={e => {
              setFormValues({ ...formValues, country: e.target.value });
            }}
            inputProps={{
              name: 'country',
              ref: register({ required: true }),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            data-testid="form-state"
            options={states}
            getOptionSelected={() => true}
            getOptionLabel={option => option.nome}
            onChange={(option, value = { nome: '' }) => {
              setSelectedState(value);
              setCities([]);
              setSelectedCity({ nome: '' });
              if (value && value.id) {
                getCities(value.id);
              }
            }}
            value={selectedState}
            loading
            loadingText={t('feedbacks:loading')}
            renderInput={params => (
              <>
                <TextField
                  {...params}
                  fullWidth
                  name="state"
                  label={t('addressStateField')}
                  color={color}
                  error={!!errors.state}
                  className={classes.formField}
                  inputRef={register({ required: true })}
                />
                <FormHelperText error={!!errors.state} />
              </>
            )}
          />
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Autocomplete
            data-testid="form-city"
            options={cities}
            getOptionSelected={() => true}
            getOptionLabel={option => option.nome}
            onChange={(option, value = { nome: '' }) => {
              setSelectedCity(value);
              if (value && value.nome) {
                setFormValues({ ...formValues, city: value.nome });
              }
            }}
            value={selectedCity}
            noOptionsText={t('feedbacks:selectYourState')}
            renderInput={params => (
              <>
                <TextField
                  fullWidth
                  {...params}
                  data-testid="form-city"
                  name="city"
                  label={t('addressCityField')}
                  color={color}
                  error={!!errors.city}
                  className={classes.formField}
                  inputRef={register({ required: true })}
                />
                <FormHelperText error={!!errors.city} />
              </>
            )}
          />
        </Grid>
      </Grid>
      <Footer
        formValues={formValues}
        onChangeField={setFormValues}
        fieldsRegister={options => register(options)}
      />
    </form>
  );
};

AddressForm.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  Footer: PropTypes.func,
  initialValues: PropTypes.object.isRequired,
  onErrorCallback: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  style: PropTypes.object,
};

AddressForm.defaultProps = {
  color: undefined,
  Footer: () => React.Fragment,
  style: {},
  onErrorCallback: () => {},
};

const styles = theme => ({
  formField: {
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  zipcodeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default withStyles(styles)(AddressForm);
