import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress } from '@material-ui/core';

const Spinner = ({ absolute, color }) =>
  absolute ? (
    <div
      style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
    >
      <CircularProgress color={color} />
    </div>
  ) : (
    <CircularProgress color={color} />
  );

Spinner.propTypes = {
  absolute: PropTypes.bool,
  color: PropTypes.string,
};
Spinner.defaultProps = {
  absolute: false,
  color: 'primary',
};

export default Spinner;
